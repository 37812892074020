import React from "react";
import "./itemSliderNews.css";
import moment from "moment";

const ItemSliderNews = (props) => (
  <div className="sliderNews-itemNews">
    <img
      className="sliderNews-itemNews-img"
      src={props.srcImg}
      alt="news"
    ></img>
    <p className="primaryNews-item-date title"
    style={{marginBottom: "5px", textAlign: "justify"}}
    >{props.title}</p>
    <p className="primaryNews-item-date">{moment(props.date).format("DD.MM.YYYY")}</p>
    <p className="primaryNews-item-discr">{props.discription}</p>
    {props.link && (
      <a
        className="primaryNews-item-link"
        href={props.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {props.nameLink}
      </a>
    )}
    {props.link2 && (
      <a
        className="primaryNews-item-link"
        href={props.link2}
        target="_blank"
        rel="noopener noreferrer"
      >
        {props.nameLink2}
      </a>
    )}
  </div>
);

export default ItemSliderNews;
