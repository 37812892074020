import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { closeMenuBurger } from "../../../../redux/actions";

class DropDownSubMenuTeacher extends Component {
  state = {
    isActiveDrop: false,
  };

  handleClick = () => {
    this.setState((prevState, prevProps) => {
      return { isActiveDrop: !prevState.isActiveDrop };
    });
  };

  render() {
    const { isActiveDrop } = this.state;
    //onClick={() => props.closeMenuBurger()}
    return (
      <>
        {isActiveDrop ? (
          <div className="submenu-navigation-drop-overlay">
            <div
              className="submenu-navigation-item-link submenu-navigation-item-drop"
              onClick={this.handleClick}
            >
              Для вчителів
              <span className="submenu-navigation-drop-span drop-span__drop"></span>
            </div>
            <div className="submenu-navigation-down-overlay">
              <NavLink
                to="/teacher"
                className="submenu-navigation-item-link submenu-navigation-item-down"
                onClick={() => this.props.closeMenuBurger()}
              >
                Електроний журнал
              </NavLink>
              <NavLink
                to="/qualification"
                className="submenu-navigation-item-link submenu-navigation-item-down"
                onClick={() => this.props.closeMenuBurger()}
              >
                Підвищення кваліфікації
              </NavLink>
              <NavLink
                to="/certificate"
                className="submenu-navigation-item-link submenu-navigation-item-down"
                onClick={() => this.props.closeMenuBurger()}
              >
                Сертифікати
              </NavLink>
              <NavLink
                to="/online/teacher"
                className="submenu-navigation-item-link submenu-navigation-item-down"
                onClick={() => this.props.closeMenuBurger()}
              >
                Онлайн-консультації
              </NavLink>
            </div>
          </div>
        ) : (
          <div className="submenu-navigation-drop-overlay">
            <div
              className="submenu-navigation-item-link submenu-navigation-item-drop"
              onClick={this.handleClick}
            >
              Для вчителів
              <span className="submenu-navigation-drop-span drop-span__down"></span>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    openMenuBurger: state.openMenuBurger,
  };
};

const mapDispatchToProps = {
  closeMenuBurger,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DropDownSubMenuTeacher);
