import React from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import Menu from "../menu/Menu";
// import MenuAuthorization from '../menuAuthorization/MenuAuthorization'
// import MenuUserInfo from '../menuUserInfo/MenuUserInfo'
import Submenu from "../submenu/Submenu";
import "./headerMain.css";

import logo1920 from "../../../images/logo_1920.svg";
import logo from "../../../images/logo.svg";
// import minonuk from '../../../images/minonuk_header.png'
// import minonuk1920 from '../../../images/minonuk_header_1920.png'
import monHeader from "../../../images/minonuk_header.svg";

const HeaderMain = (props) => (
  <header className="header">
    <div className="header-overlay">
      <div className="header-menu-overlay">
        <div className="logo">
          <NavLink to="/home">
            <picture>
              <source media="(min-width: 992px)" srcSet={logo1920} />
              <img src={logo} alt="logo" />
            </picture>
          </NavLink>
        </div>
        <Menu />
      </div>
      {/* <MenuUserInfo /> */}
      <h1 className="header-title">{props.title}</h1>
      <p className="header-title__span">{props.span}</p>
      {/* <p className="header-discription">Працюємо під Грифом “Рекомендовано <span></span>Міністерством освіти і науки”</p> */}
      <div className="header-recom">
        <div className="header-recom-img">
          <picture>
            {/* <source media="(min-width: 1920px)" srcSet={news21920} /> */}
            <source media="(min-width: 1200px)" srcSet={monHeader} />
            {/* <source media="(min-width: 992px)" srcSet={news2992} /> */}
            <img src={monHeader} alt="world_card" />
          </picture>
        </div>
        <div className="header-recom-discr">
          <p>
            Рекомендовано Міністерством{" "}
            <span className="header-recom-discr__span"></span>освіти і науки
            України{" "}
            <span className="header-recom-discr__italic">
              (лист МОН №1/11-9213 від 18.10.2019)
            </span>
          </p>
        </div>
      </div>
    </div>
    {props.openMenuBurger && <Submenu />}
  </header>
);

const mapStateToProps = (state) => {
  return {
    menuAuth: state.openMenuAuth,
    openMenuBurger: state.openMenuBurger,
  };
};

// const mapDispatchToProps = {
//     // openMenuAuth,
//     // closeMenuAuth
// };

export default connect(mapStateToProps)(HeaderMain);
