import React from 'react'
import './titleQuestions.css'

const TitleQuestions = ({ title, handleClick }) => (
    <div className='questions-titleQuestions' onClick={handleClick}>
        <p className='titleQuestions__title'>{title}</p>
        <button className='titleQuestions__btn'></button>
    </div>
);

export default TitleQuestions;