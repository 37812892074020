const initialState = {
    curentVideo: {
        key: "0",
        srcPlay: "https://www.youtube.com/embed/CsAlVZMfHzE",
        srcDownload: "#video0",
        title: "1. «Початок роботи з планшетом»"
    },
    authorisation: false,
    openMenuAuth: false,
    openMenuBurger: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case "LOG_IN":
            return {
                ...state,
                authorisation: true
            };
        case "LOG_OUT":
            return {
                ...state,
                authorisation: false
            };
        case "INITIAL_DATA":
            return {
                ...state,
                data: action.payload
            };
        case "ADD_DATA_ITEM":
            return {
                ...state,
                data: [...state.data, action.payload]
            };
        case "DELETE_DATA_ITEM":
            return {
                ...state,
                data: state.data.filter(data => data.id !== action.payload)
            };
        case "OPEN_MENU_AUTHORIZATION":
            return {
                ...state,
                openMenuAuth: !state.openMenuAuth
                // openMenuAuth: true
            };
        case "CLOSE_MENU_AUTHORIZATION":
            return {
                ...state,
                openMenuAuth: false
            };
        case "OPEN_MENU_BURGER":
            return {
                ...state,
                openMenuBurger: true
            };
        case "CLOSE_MENU_BURGER":
            return {
                ...state,
                openMenuBurger: false
            };
        case "CHANGE_CURENT_VIDEO":
            return {
                ...state,
                curentVideo: action.payload
            };
        default:
            return state;
    }
};

export default reducer;