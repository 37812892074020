import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class DropDownMenuTeacher extends Component {
  state = {
    isActiveDrop: false,
  };

  handleClick = () => {
    this.setState((prevState, prevProps) => {
      return { isActiveDrop: !prevState.isActiveDrop };
    });
  };

  render() {
    const { isActiveDrop } = this.state;

    return (
      <>
        {isActiveDrop ? (
          <div className="menu-navigation-drop-overlay">
            <div
              className="menu-navigation-item-link menu-navigation-item-drop"
              onClick={this.handleClick}
            >
              Для вчителів
              <span className="menu-navigation-drop-span drop-span__drop"></span>
            </div>
            <div className="menu-navigation-down-overlay">
              <NavLink
                to="/teacher"
                className="menu-navigation-item-link menu-navigation-item-down"
              >
                Електроний журнал
              </NavLink>
              <NavLink
                to="/qualification"
                className="menu-navigation-item-link menu-navigation-item-down"
              >
                Підвищення кваліфікації
              </NavLink>
              <NavLink
                to="/certificate"
                className="menu-navigation-item-link menu-navigation-item-down"
              >
                Сертифікати
              </NavLink>
              <NavLink
                to="/online/teacher"
                className="menu-navigation-item-link menu-navigation-item-down"
              >
                Онлайн-консультації
              </NavLink>
            </div>
          </div>
        ) : (
          <div className="menu-navigation-drop-overlay">
            <div
              className="menu-navigation-item-link menu-navigation-item-drop"
              onClick={this.handleClick}
            >
              Для вчителів
              <span className="menu-navigation-drop-span drop-span__down"></span>
            </div>
          </div>
        )}
      </>
    );
  }
}

// function DropMenu() {
//     <div className="menu-navigation-item-link">Для вчителів</div>
// }

export default DropDownMenuTeacher;
