import { createStore } from "redux";
// import persistState from "redux-localstorage";
// import { devToolsEnhancer } from "redux-devtools-extension";
import reducer from "../redux/reducer";

// const enhancer = compose(devToolsEnhancer(), persistState());
// const enhancer = compose(devToolsEnhancer());

const store = createStore(reducer);
// const store = createStore(reducer, enhancer);

export default store;
