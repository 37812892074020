import React from "react";
import "./itemSliderReviews.css";

import avatarXl from "../../../../../images/reviews_avatar_xl1.png";
import starXl from "../../../../../images/star_xl.png";

const ItemSliderReviews1 = () => (
  <div className="sliderReviews-itemReviews-overlay">
    <div className="sliderReviews-itemReviews">
      <div className="itemReviews-img">
        <div className="itemReviews-img-avatar">
          <picture>
            <source media="(min-width: 1920px)" srcSet={avatarXl} />
            <img src={avatarXl} alt="avatar" />
          </picture>
        </div>
        <p className="itemReviews-img-title">Леонід Березовський</p>
        <p className="itemReviews-img-discr">
          Школа І-ІІІ ступенів №270 Деснянського району м. Києва
        </p>
      </div>
      <div className="itemReviews-review">
        <p className="itemReviews-review-discr">
          Користуюсь цією програмою майже 4 місяці. Приємно вражений, що в
          Україні з’явився такий інформативний та зручний мобільний додаток.
          Дякуючи цьому додатку, я більше приймаю участь в освітньому процесі
          своїх дітей. Молодці хлопці! Бажаю розвитку!
        </p>
        <picture>
          <source media="(min-width: 1920px)" srcSet={starXl} />
          <img src={starXl} alt="avatar" />
        </picture>
      </div>
    </div>
  </div>
);

export default ItemSliderReviews1;
