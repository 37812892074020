import React from 'react'
import './itemSliderReviews.css'

// import avatarXs from '../../../../../images/reviews_avatar_xs.png'
import avatarXl from '../../../../../images/reviews_avatar_xl3.png'
// import starXs from '../../../../../images/star_xs.png'
import starXl from '../../../../../images/star_xl1.png'

const ItemSliderReviews3 = () => (
    <div className='sliderReviews-itemReviews-overlay'>
        <div className='sliderReviews-itemReviews'>
            <div className='itemReviews-img'>
                <div className="itemReviews-img-avatar">
                    <picture>
                        <source media="(min-width: 1920px)" srcSet={avatarXl} />
                        <img src={avatarXl} alt='avatar' />
                    </picture>
                </div>
                <p className='itemReviews-img-title'>Марина Ковтун</p>
                <p className='itemReviews-img-discr'>м. Київ</p>
            </div>
            <div className='itemReviews-review'>
                <p className='itemReviews-review-discr'>Щоденник подобається, і якщо вчитель заповняє його - задовільняє на 100% Дуже корисна програма для України!</p>
                <picture>
                    <source media="(min-width: 1920px)" srcSet={starXl} />
                    <img src={starXl} alt='avatar' />
                </picture>
            </div>
        </div >
    </div>
);

export default ItemSliderReviews3;