import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import HomePage from './pages/homePage/HomePage';
//import QualificationPage from './pages/qualificationPage/QualificationPage';
//import SertificatePage from './pages/sertificatePage/SertificatePage';
//import TrainingPage from './pages/trainingPage/TrainingPage';
//import SchoolRegistrationPage from './pages/schoolRegistrationPage/SchoolRegistrationPage';
//import TeacherPage from './pages/teacherPage/TeacherPage';
//import StudentsPage from './pages/studentsPage/StudentsPage';
//import ParentPage from './pages/parentPage/ParentPage';
//import AboutPage from './pages/aboutPage/AboutPage';
//import NewsPage from './pages/newsPage/NewsPage';
//import LinksPage from './pages/linksPage/LinksPage';
//import ContactsPage from './pages/contactsPage/ContactsPage';
//import DonatsPage from './pages/donatsPage/DonatsPage';
//import ConfirmEmailPage from './pages/confirmEmailPage/ConfirmEmailPage';
//import ConfirmPasswordPage from "./pages/confirmPasswordPage/ConfirmPasswordPage";
//import ConfirmErrorPageUrl from './pages/confirmErrorPage/ConfirmErrorPageUrl';
//import FaqTeachePage from './pages/faqPages/FaqTeachePage';
//import FaqParentPage from './pages/faqPages/FaqParentPage';
//import FaqStudentPage from './pages/faqPages/FaqStudentPage';
//import OnlineСonsultationTeacherPage from './pages/onlineСonsultationTeacherPage/OnlineСonsultationTeacherPage';
//import OnlineСonsultationParentPage from './pages/onlineСonsultationParentPage/OnlineСonsultationParentPage';
//import DefaultPage from './pages/defaultPage/DefaultPage';
import Footer from './components/footer/Footer';

import './App.css';

const SchoolRegistrationPage = lazy(() =>
  import('./pages/schoolRegistrationPage/SchoolRegistrationPage'),
);
const AboutPage = lazy(() => import('./pages/aboutPage/AboutPage'));

const QualificationPage = lazy(() =>
  import('./pages/qualificationPage/QualificationPage'),
);

const SertificatePage = lazy(() =>
  import('./pages/sertificatePage/SertificatePage'),
);

const TrainingPage = lazy(() => import('./pages/trainingPage/TrainingPage'));

const TeacherPage = lazy(() => import('./pages/teacherPage/TeacherPage'));

const StudentsPage = lazy(() => import('./pages/studentsPage/StudentsPage'));

const ParentPage = lazy(() => import('./pages/parentPage/ParentPage'));

const NewsPage = lazy(() => import('./pages/newsPage/NewsPage'));

const LinksPage = lazy(() => import('./pages/linksPage/LinksPage'));

const ContactsPage = lazy(() => import('./pages/contactsPage/ContactsPage'));

const DonatsPage = lazy(() => import('./pages/donatsPage/DonatsPage'));

const ConfirmEmailPage = lazy(() =>
  import('./pages/confirmEmailPage/ConfirmEmailPage'),
);

const ConfirmErrorPageUrl = lazy(() =>
  import('./pages/confirmErrorPage/ConfirmErrorPageUrl'),
);

const FaqTeachePage = lazy(() => import('./pages/faqPages/FaqTeachePage'));

const FaqParentPage = lazy(() => import('./pages/faqPages/FaqParentPage'));

const FaqStudentPage = lazy(() => import('./pages/faqPages/FaqStudentPage'));

const OnlineСonsultationTeacherPage = lazy(() =>
  import('./pages/onlineСonsultationTeacherPage/OnlineСonsultationTeacherPage'),
);

const OnlineСonsultationParentPage = lazy(() =>
  import('./pages/onlineСonsultationParentPage/OnlineСonsultationParentPage'),
);

const DefaultPage = lazy(() => import('./pages/defaultPage/DefaultPage'));

function App() {
  return (
    <div>
      <div>
        <Suspense fallback={''}>
          <Switch>
            <Route path='/' exact component={HomePage} />
            <Route path='/home' component={HomePage} />
            <Route
              path='/schoolRegistration'
              component={SchoolRegistrationPage}
            />
            <Route path='/teacher' component={TeacherPage} />
            <Route path='/qualification' component={QualificationPage} />
            <Route path='/certificate' component={SertificatePage} />
            <Route path='/students' component={StudentsPage} />
            <Route path='/parents' component={ParentPage} />
            <Route path='/about' component={AboutPage} />
            <Route path='/news' component={NewsPage} />
            <Route path='/links' component={LinksPage} />
            <Route path='/contacts' component={ContactsPage} />
            <Route path='/donate' component={DonatsPage} />
            <Route path='/confirmemail' component={ConfirmEmailPage} />
            {/* <Route path="/confirmpassword" component={ConfirmPasswordPage} /> */}
            <Route path='/confirmemailparent' component={ConfirmEmailPage} />
            {/* <Route path="/confirmpasswordparent" component={ConfirmPasswordPage} />
          <Route path="/confirmsuccessteacher" component={ConfirmSuccessPage} />*/}
            <Route path='/*****confirmsuccessparent####' />
            <Route path='/urlerror' component={ConfirmErrorPageUrl} />
            <Route path='/faq/teachers' component={FaqTeachePage} />
            <Route path='/faq/parents' component={FaqParentPage} />
            <Route path='/faq/students' component={FaqStudentPage} />
            <Route
              path='/online/teacher'
              component={OnlineСonsultationTeacherPage}
            />
            <Route
              path='/online/parent'
              component={OnlineСonsultationParentPage}
            />
            <Route path='/training/certificate/:uid' component={TrainingPage} />
            <Route component={DefaultPage} />
          </Switch>
        </Suspense>
      </div>
      <Footer />
    </div>
  );
}

export default App;
