// const configTest = {
//   version: "v. 1.04.38",
//   domen: "https://site.eschool-ua.com",
//   newsDomen: "https://site.eschool-ua.com",
//   api: "https://api-test.eschool-ua.com",
//   amapiDomen: "https://amapi-test.eschool-ua.com",
//   apikey: "f417a941-bd7f-4b68-bf74-175375f94e9a",
// };

// export default configTest; 


const configProd = {
    version: "v. 1.04.38",
    domen: "https://eschool-ua.com",
    newsDomen: "https://eschool-ua.com",
    api: "https://api.eschool-ua.com",
    amapiDomen: "https://amapi.eschool-ua.com",
    apikey: "9c3e51a6-7ea0-4034-ac06-72d217cddca0",
  };

  export default configProd;


  
