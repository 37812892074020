import React from "react";
import "./contentQuestions.css";

const ContentQuestions = ({ type, item, handleClick }) => {
  const firstText = item?.discription?.split(":")[0];
  const lastText = item?.discription?.split(":")[1];
  const lastTextArray = lastText?.split("$");
  return (
    <div className="questions-contentQuestions" onClick={handleClick}>
      <div className="contentQuestions-header">
        <p className="contentQuestions__title">{item.title}</p>
        <button className="contentQuestions__btn"></button>
      </div>
      <div className="contentQuestions-answer">
        {type === undefined && (
          <p className="contentQuestions__discr">{item.discription}</p>
        )}
        {type === "marked_list" && (
          
          <ul>
              <p style={{ textAlign: "justify", marginBottom: "8px" }}>{firstText}:</p>
            {lastTextArray?.map((text, idx) => (
              <li
                key={idx}
                style={{ textAlign: "justify", marginBottom: "8px" }}
              >
                {text}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default ContentQuestions;
