import axios from 'axios';
import config from './config';

export default {
  async postFeedback(obj, csrf) {
    try {
      const data = await axios.post(`${config.domen}/feedback`, obj, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrf,
        },
      });
      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async postRegistration(obj, csrf) {
    try {
      const data = await axios.post(`${config.domen}/school/register`, obj, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrf,
        },
      });
      return data;
    } catch (err) {
      return err;
    }
  },
  async postRegistration_fetch(obj, csrf) {
    try {
      const response = await fetch(`${config.domen}/school/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrf,
        },
        body: obj,
      });
      if (response.ok) {
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  },
  ///certificates/
  async getSertificateInfo(value, csrf) {
    try {
      const data = await axios.get(
        `${config.domen}/training/certificates/${value}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrf,
          },
        },
      );
      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  //?search=
  async searchSertificate(value, csrf) {
    try {
      const data = await axios.get(`${config.domen}/training/certificates`, {
        params: {
          search: value,
        },
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrf,
        },
      });
      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  //news/
  async getNewsInfo() {
    try {
      const data = await axios.get(`${config.newsDomen}/news/list`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async getCity() {
    try {
      const response = await axios
        .get(`${config.domen}/city`)
        .then((response) => response.data);
      return response;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async getRegion() {
    try {
      const response = await axios
        .get(`https://ttg.eschool-ua.com/api/katottg?category=O&page_size=30`)
        .then((response) => response.data.results);
      return response;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async getDistricts(parentId) {
    try {
      const response = await axios
        .get(
          `https://ttg.eschool-ua.com/api/katottg?parent=${parentId}&page_size=30`,
        )
        .then((response) => response.data.results);
      return response;
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async getEdebo(search, type) {
    try {
      const response = await axios
        .get(
          `${config.amapiDomen}/search/edebo?q=${search}&school_type=${type}`,
        )
        .then((response) => response.data.items);
      return response;
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async getEDEBO(Id, url, type) {
    try {
      let response = null;
      if (Id) {
        response = await axios
          .get(`${config.amapiDomen}${url}=${Id}&school_type=${type}`)
          .then((response) => response.data);
      }
      return response;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async postEmailRegistration(obj) {
    try {
      const data = await axios.post(`${config.api}/ConfirmEmailWeb`, obj, {
        headers: {
          'Content-Type': 'application/json',
          'Api-key': config.apikey,
        },
      });
      return data;
    } catch (err) {
      return err;
    }
  },
  async postPasswordRegistration(obj, token) {
    try {
      const data = await axios.post(`${config.api}/profile`, obj, {
        headers: {
          'Content-Type': 'application/json',
          'Api-key': config.apikey,
          'X-Auth-Token': token,
        },
      });
      return data;
    } catch (err) {
      return err;
    }
  },
};
