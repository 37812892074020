import React from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { closeMenuBurger } from "../../../redux/actions";
import DropDownSubMenuTeacher from "./dropDownSubMenu/DropDownSubMenuTeacher";
import DropDownSubMenuParent from "./dropDownSubMenu/DropDownSubMenuParent";
import "./submenu.css";

const Submenu = (props) => {
  return (
    <div className="submenu">
      <ul className="submenu-navigation">
        <li
          className="submenu-navigation-item submenu-navigation-item-js"
          onClick={() => props.closeMenuBurger()}
        >
          <NavLink
            className="submenu-navigation-item-link"
            to="/schoolRegistration"
          >
            Зареєструвати заклад
          </NavLink>
        </li>
        <li
          className="submenu-navigation-item submenu-navigation-item-js"
          //   onClick={() => props.closeMenuBurger()}
        >
          {/* <NavLink className="submenu-navigation-item-link" to="/teacher">Для вчителів</NavLink> */}
          <DropDownSubMenuTeacher />
        </li>
        <li
          className="submenu-navigation-item submenu-navigation-item-js"
          onClick={() => props.closeMenuBurger()}
        >
          <NavLink className="submenu-navigation-item-link" to="/students">
            Для учнів
          </NavLink>
        </li>
        <li
          className="submenu-navigation-item submenu-navigation-item-js"
          // onClick={() => props.closeMenuBurger()}
        >
          {/* <NavLink
            className="submenu-navigation-item-link submenu-navigation-item-js"
            to="/parents"
          >
            Для батьків
          </NavLink> */}
          <DropDownSubMenuParent />
        </li>
        <li
          className="submenu-navigation-item submenu-navigation-item-js"
          onClick={() => props.closeMenuBurger()}
        >
          <NavLink
            className="submenu-navigation-item-link submenu-navigation-item-js"
            to="/about"
          >
            Чому Єдина Школа?
          </NavLink>
        </li>
        <li
          className="submenu-navigation-item submenu-navigation-item-js"
          onClick={() => props.closeMenuBurger()}
        >
          <NavLink className="submenu-navigation-item-link" to="/news">
            Новини
          </NavLink>
        </li>
        <li
          className="submenu-navigation-item submenu-navigation-item-js"
          onClick={() => props.closeMenuBurger()}
        >
          <NavLink className="submenu-navigation-item-link" to="/links">
            Корисні посиланння
          </NavLink>
        </li>
        <li
          className="submenu-navigation-item submenu-navigation-item-js"
          onClick={() => props.closeMenuBurger()}
        >
          <NavLink className="submenu-navigation-item-link" to="/contacts">
            Контакти
          </NavLink>
        </li>
        <li
          className="submenu-navigation-item submenu-navigation-item-js"
          onClick={() => props.closeMenuBurger()}
        >
          <NavLink className="submenu-navigation-item-link" to="/donate">
            Донати Батальйону "Стугна"
          </NavLink>
        </li>
      </ul>
      <div
        className="closeSubmenu"
        onClick={() => props.closeMenuBurger()}
      ></div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    openMenuBurger: state.openMenuBurger,
  };
};

const mapDispatchToProps = {
  closeMenuBurger,
};

export default connect(mapStateToProps, mapDispatchToProps)(Submenu);
