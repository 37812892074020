import React from "react";
import Slider from "react-slick";
import ItemSliderNews from "../sliderNews/itemSliderNews/ItemSliderNews";
import config from "../../../../services/config";
// import NewsItemPrimary from '../../mainNews/newsItemPrimary/NewsItemPrimary'
import "../sliderNews/sliderNews.css";

// import data from "../../../../data/news.json";
// import newsImg1 from "../../../../images/newsImg1.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// const dataNews = data
//   .filter((item) => item.primary)
//   .filter((item) => item.event);
// const arrImgNews = [newsImg1, newsImg1, newsImg1];

const SamplePrevArrow = (props) => {
  return (
    <button
      className="sliderArrow-btnPrev newsArrow"
      onClick={props.onClick}
    ></button>
  );
};

const SampleNextArrow = (props) => {
  return (
    <button
      className="sliderArrow-btnNext newsArrow"
      onClick={props.onClick}
    ></button>
  );
};

const SliderEvents = ({ dataNews }) => {

  const settings = {
    // dots: true,
    // infinite: true,
    autoplay: true,
    pauseOnHover: true,
    speed: 2000,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className="sliderNews">
      {dataNews?.length > 0 && (
        <Slider {...settings} className="sliderNews-overlayNews">
          <ItemSliderNews
            date={dataNews[0].Date}
            discription={dataNews[0].Description}
            link={dataNews[0].Link}
            title={dataNews[0]?.Title}
            nameLink={"Детальніше"}
            // link2={dataNews[0].link2}
            // nameLink2={dataNews[0].nameLink2}
            srcImg={`${config.domen}/news/images/${dataNews[0].Image}`}
          />
          <ItemSliderNews
            date={dataNews[1].Date}
            discription={dataNews[1].Description}
            link={dataNews[1].Link}
            title={dataNews[1]?.Title}
            nameLink={"Детальніше"}
            // link2={dataNews[2].link2}
            // nameLink2={dataNews[2].nameLink2}
            srcImg={`${config.domen}/news/images/${dataNews[1].Image}`}
          />
          <ItemSliderNews
            date={dataNews[2].Date}
            discription={dataNews[2].Description}
            link={dataNews[2].Link}
            title={dataNews[2]?.Title}
            nameLink={"Детальніше"}
            // link2={dataNews[2].link2}
            // nameLink2={dataNews[2].nameLink2}
            srcImg={`${config.domen}/news/images/${dataNews[2].Image}`}
          />
        </Slider>
      )}
    </div>
  );
};

export default SliderEvents;
