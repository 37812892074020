import React, { Component } from "react";
import TitleQuestions from "./titleQuestions/TitleQuestions";
import ContentQuestions from "./contentQuestions/ContentQuestions";
// import data from '../../../../data/faq.json'

class Questions extends Component {
  state = {
    isActive: [],
  };

  componentDidMount() {
    const newIsActive = this.props.data.map((item) => false);
    this.setState({ isActive: newIsActive });
  }

  handleToggle = (index) => {
    let newIsActive = this.state.isActive;
    newIsActive[index] = !newIsActive[index];
    this.setState({ isActive: newIsActive });
  };

  render() {
    return (
      <div>
        {this.props.data.map((item, index) => {
          return !this.state.isActive[index] ? (
            <TitleQuestions
              key={`faq-${index}`}
              index={index}
              title={item.title}
              handleClick={() => this.handleToggle(index)}
            />
          ) : (
            <ContentQuestions
              key={`faq-${index}`}
              index={index}
              item={item}
              type={item.type}
              discription={item.discription}
              handleClick={() => this.handleToggle(index)}
            />
          );
        })}
      </div>
    );
  }
}

export default Questions;
