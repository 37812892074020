import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class DropDownMenuParent extends Component {
  state = {
    isActiveDrop: false,
  };

  handleClick = () => {
    this.setState((prevState, prevProps) => {
      return { isActiveDrop: !prevState.isActiveDrop };
    });
  };

  render() {
    const { isActiveDrop } = this.state;

    return (
      <>
        {isActiveDrop ? (
          <div className="menu-navigation-drop-overlay">
            <div
              className="menu-navigation-item-link menu-navigation-item-drop"
              onClick={this.handleClick}
            >
              Для батьків
              <span className="menu-navigation-drop-span drop-span__drop"></span>
            </div>
            <div className="menu-navigation-down-overlay">
              <NavLink
                to="/parents"
                className="menu-navigation-item-link menu-navigation-item-down"
              >
                Електронний щоденник
              </NavLink>
              <NavLink
                to="/online/parent"
                className="menu-navigation-item-link menu-navigation-item-down"
              >
                Онлайн-консультації
              </NavLink>
            </div>
          </div>
        ) : (
          <div className="menu-navigation-drop-overlay">
            <div
              className="menu-navigation-item-link menu-navigation-item-drop"
              onClick={this.handleClick}
            >
              Для батьків
              <span className="menu-navigation-drop-span drop-span__down"></span>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default DropDownMenuParent;
