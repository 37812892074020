import Type from "./types";

export const logIn = () => ({
    type: Type.LOG_IN
});

export const logOut = () => ({
    type: Type.LOG_OUT
});

export const openMenuAuth = () => ({
    type: Type.OPEN_MENU_AUTHORIZATION
});

export const closeMenuAuth = () => ({
    type: Type.CLOSE_MENU_AUTHORIZATION
});

export const openMenuBurger = () => ({
    type: Type.OPEN_MENU_BURGER
});

export const closeMenuBurger = () => ({
    type: Type.CLOSE_MENU_BURGER
});

export const changeCurentVideo = value => ({
    type: Type.CHANGE_CURENT_VIDEO,
    payload: value
});