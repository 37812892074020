export default {
    LOG_IN: "LOG_IN",
    LOG_OUT: "LOG_OUT",

    GET_NEWS: "GET_NEWS",

    OPEN_MENU_AUTHORIZATION: "OPEN_MENU_AUTHORIZATION",
    CLOSE_MENU_AUTHORIZATION: "CLOSE_MENU_AUTHORIZATION",
    OPEN_MENU_BURGER: "OPEN_MENU_BURGER",
    CLOSE_MENU_BURGER: "CLOSE_MENU_BURGER",
    CHANGE_CURENT_VIDEO: "CHANGE_CURENT_VIDEO"
};