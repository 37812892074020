import React from 'react'
import Slider from "react-slick";
import ItemSliderReviews1 from './itemSliderReviews/ItemSliderReviews1'
import ItemSliderReviews2 from './itemSliderReviews/ItemSliderReviews2'
import ItemSliderReviews3 from './itemSliderReviews/ItemSliderReviews3'
import '../sliderNews/sliderNews.css'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SamplePrevArrow = (props) => {
    return (<button className="sliderArrow-btnPrev reviewsArrow" onClick={props.onClick}></button>)
}

const SampleNextArrow = (props) => {
    return (<button className="sliderArrow-btnNext reviewsArrow" onClick={props.onClick}></button>)
}

const SliderReviews = () => {
    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        pauseOnHover: true,
        speed: 2000,
        autoplaySpeed: 5000,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        // dotsClass: "slick-dots slick-thumb",
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };
    return (
        <div className='sliderReviews'>
            <Slider {...settings} className='sliderReviews-overlayReviews'>
                <ItemSliderReviews1 />
                <ItemSliderReviews2 />
                <ItemSliderReviews3 />
            </Slider>
        </div>
    );
}

export default SliderReviews;