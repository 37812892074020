import React from 'react'
import HeaderMain from '../../components/header/headerMain/HeaderMain'
import MainHome from '../../components/main/mainHome/MainHome'

const HomePage = (props) => (
    <>
        <HeaderMain title='Система "Єдина школа" для закладів освіти' />
        <main>
            <MainHome />
        </main>
    </>
);

export default HomePage;