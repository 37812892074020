import React from "react";
import "./itemSliderReviews.css";

import avatarXl from "../../../../../images/reviews_avatar_xl2.png";
import starXl from "../../../../../images/star_xl.png";

const ItemSliderReviews2 = () => (
  <div className="sliderReviews-itemReviews-overlay">
    <div className="sliderReviews-itemReviews">
      <div className="itemReviews-img">
        <div className="itemReviews-img-avatar">
          <picture>
            <source media="(min-width: 1920px)" srcSet={avatarXl} />
            <img src={avatarXl} alt="avatar" />
          </picture>
        </div>
        <p className="itemReviews-img-title">Оксана Демченко</p>
        <p className="itemReviews-img-discr">
          Школа І-ІІІ ступенів №15 м. Херсон
        </p>
      </div>
      <div className="itemReviews-review">
        <p className="itemReviews-review-discr">
          Чудовий додаток, особливо із веденням онлайн навчання. Можна не
          розпилюватись по месенджарам, зумах і т.д. Все, що потрібно для
          навчання, можна отримати в одному місці.
        </p>
        <picture>
          <source media="(min-width: 1920px)" srcSet={starXl} />
          <img src={starXl} alt="avatar" />
        </picture>
      </div>
    </div>
  </div>
);

export default ItemSliderReviews2;
