import React from "react";
import Slider from "react-slick";
import ItemSliderNews from "./itemSliderNews/ItemSliderNews";
import config from "../../../../services/config";
// import NewsItemPrimary from "../../mainNews/newsItemPrimary/NewsItemPrimary";
import "./sliderNews.css";

// import data from "../../../../data/news.json";
// import newsImg1 from "../../../../images/newsImg2511.jpg";
// import newsImg2 from "../../../../images/newsImg1103.jpg";
// import newsImg3 from "../../../../images/newsImg1101.jpg";
// import newsImg3 from "../../../../images/newsImg3010.JPG";
// import newsImg3 from "../../../../images/newsImg2509.JPG";
// import newsImg3 from "../../../../images/newsImg1609.jpg";
// import newsImg3 from '../../../../images/newsImg0309.JPG'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



const SamplePrevArrow = (props) => {
  return (
    <button
      className="sliderArrow-btnPrev newsArrow"
      onClick={props.onClick}
    ></button>
  );
};

const SampleNextArrow = (props) => {
  return (
    <button
      className="sliderArrow-btnNext newsArrow"
      onClick={props.onClick}
    ></button>
  );
};

const SliderNews = ({ news }) => {

  let dataNews = ((news?.filter((item) => item.State === "published"))?.filter((item) => item.IsPrimary))?.slice(0, 3);
  dataNews = dataNews?.sort((a,b) => new Date(b.Date) - new Date(a.Date));
  //const arrImgNews = [newsImg1, newsImg2, newsImg3];

  const settings = {
    // dots: true,
    // infinite: true,
    autoplay: true,
    pauseOnHover: true,
    speed: 2000,
    autoplaySpeed: 5000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  if(dataNews?.length === 0){
    return null;
  }
  
  return (
    <div className="sliderNews">
      <Slider {...settings} className="sliderNews-overlayNews">
        <ItemSliderNews
          date={dataNews[0]?.Date}
          discription={dataNews[0]?.Description}
          link={dataNews[0]?.Link}
          title={dataNews[0]?.Title}
          nameLink={"Детальніше"}
          //link2={dataNews[0]?.Link2}
          //nameLink2={dataNews[0]?.nameLink2}
          srcImg={`${config.domen}/news/images/${dataNews[0]?.Image}`}
        />
        <ItemSliderNews
          date={dataNews[1]?.Date}
          discription={dataNews[1]?.Description}
          link={dataNews[1]?.Link}
          title={dataNews[1]?.Title}
          nameLink={"Детальніше"}
          // link2={dataNews[1]?.link2}
          // nameLink2={dataNews[1]?.nameLink2}
          srcImg={`${config.domen}/news/images/${dataNews[1]?.Image}`}
        />
        <ItemSliderNews
          date={dataNews[2]?.Date}
          discription={dataNews[2]?.Description}
          link={dataNews[2]?.Link}
          title={dataNews[2]?.Title}
          nameLink={"Детальніше"}
          // link2={dataNews[2]?.link2}
          // nameLink2={dataNews[2]?.nameLink2}
          srcImg={`${config.domen}/news/images/${dataNews[2]?.Image}`}
        />
      </Slider>
    </div>
  );
};

export default SliderNews;
